@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body{
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 15px;
}
body.openModal{
  overflow: hidden;
}
img{
  max-width: 100%;
}
ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}
a,a:hover{
  text-decoration: none;
  color: #f5a688;
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus,.btn-check:focus+.btn, .btn:focus,button:focus{
  box-shadow: none;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Noto Sans Hebrew", sans-serif;
  line-height: 150%;
  margin: 0 0 .7rem;
  font-weight: 700;
}
h1,h2,h3{
  font-size: 30px;
  line-height: 1.35;
  font-weight: 700;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 1rem;
}
.font-12{
  font-size: 12px;
}
.font-18{
  font-size: 18px;
  line-height: 26px;
}
.font-20{
  font-size: 20px;
  line-height: 28px;
}
.font-22{
  font-size: 22px;
  line-height: 28px;
}
.pt-90{
  padding-top: 90px;
}
.pb-90{
  padding-bottom: 90px;
}
.ptb-90{
  padding-top: 90px;
  padding-bottom: 90px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30{
  margin-bottom: 30px;
}
.showOnMobile {
  display: none;
}
.p-dropdown:not(.p-disabled).p-focus{
  box-shadow: none;
}
.p-accordion .p-accordion-content{
  color: #000;
}
.btn-primary,.btn-primary:hover,.btn-secondary,.btn-secondary:hover,.btn-secondary:focus {
  min-width: 150px;
  padding: 11px 30px 12px;
  border-radius: 40px;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  background: #000;
  color: #fff;
  font-weight: 400;
  border: none;
}
.btn-secondary,.btn-secondary:hover  {
  background: #f5a688;
  color: #fff;
}
.orangeButton,
.smallblackButton {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  color: #000;
  line-height: 21px;
  background-color: #f5a688;
  font-weight: 600;
  border-radius: 10px;
  min-width: 124px;
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.orangeButton::before,
.smallblackButton::before{
  content: "";
  position: absolute;
  top: 35.09375px;
  left: 75.578125px;
  width: 175px;
  height: 175px;
  background: radial-gradient(circle closest-side, rgba(255,255,255,0.35), transparent);
  transform: translate(-50%, -50%);
  transition: width .2s ease, height .2s ease;
  opacity: .65;
  visibility: hidden;
  z-index: -1;
}
.orangeButton:hover::before{
  visibility: visible;
}
.orangeButton:hover{
  color: #000;
}
.orangeButton:hover::after {
  opacity: 0;
}
.orangeRadiusButton,
.btn.btn-info,
.orangeRadiusButton:hover,
.btn.btn-info:hover {
  padding: 11px 30px 12px;
  font-size: 14px;
  background-color: #f5a688;
  font-weight: 400;
  border-radius: 50px;
  min-width: 124px;
  text-align: center;
  border: none;
  color: #fff;
  display: inline-block;
}
.orangeButton.radius-50 {
  padding: 11px 30px 12px;
  min-width: 150px;
  border-radius: 50px;
  font-weight: 400;
}
.smallblackButton {
  padding: 6px 18px;
  line-height: 23px;
  min-width: 0;
  font-size: 12px;
  background-color: #000;
  color: #fff;
}
body .commonBlackButton {
  background: #000;
  color: #fff;
  min-width: 150px;
  border-radius: 40px;
  padding: 10px 12px 11px;
  font-size: 14px;
  font-weight: 400;
}
.greyRadiusButton,.blackRadiusButton{
  position: relative;
  display: block;
  padding: 12px 15px;
  background-color: #f1f1f1;
  color: #000;
  text-decoration: none;
  font-weight: 700;
  border-radius: 50px;
  text-align: center;
}
.blackRadiusButton{
  background: #000;
  color: #fff;
}
.blackRadiusButton:hover{
  background: #000;
  color: #fff;
}
.text-black{
  color: #000;
}
.p-carousel.p-component {
  direction: ltr;
}
.styleScroll {
  overflow: auto;
}
.p-carousel-items-container {
  padding-bottom: 1px;
}
.styleScroll::-webkit-scrollbar,
.rbc-time-content::-webkit-scrollbar,
.ratingModal .modal_body::-webkit-scrollbar,
.p-dropdown-items-wrapper::-webkit-scrollbar,
.trevalKitRegion::-webkit-scrollbar{
  width: 7px;
}
.styleScroll::-webkit-scrollbar-track,
.rbc-time-content::-webkit-scrollbar-track,
.ratingModal .modal_body::-webkit-scrollbar-track,
.p-dropdown-items-wrapper::-webkit-scrollbar-track,
.trevalKitRegion::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.styleScroll::-webkit-scrollbar-thumb,
.rbc-time-content::-webkit-scrollbar-thumb,
.ratingModal .modal_body::-webkit-scrollbar-thumb,
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb,
.trevalKitRegion::-webkit-scrollbar-thumb {
  background: #c5c5c5; 
}
hr {
  opacity: 1;
  background: #e7e7e7;
}
.container{
  max-width: 1370px;
  margin: 0 auto;
  padding: 0 15px;
}
.notLoggedIn .container{
  max-width: 1170px; 
}
.siteMainFooter {
  background: #f5f5f5;
}
.siteMainHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: background-color .3s;
  background-color: hsla(0, 0%, 100%, .8);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  color: #000;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, .045);
  z-index: 120;
}
.notLoggedIn .siteMainHeader  .container,
.notLoggedIn .scrollingTabsWrapper .container{
  max-width: 1200px;
}
/* .siteMainHeader  .container,
.scrollingTabsWrapper .container{
  max-width: 1400px;
} */
.headerTopNavMobile{
  display: none;
}
.headerTopLogo.text-center {
  margin: 5px auto;
}
.siteMainHeader img.siteImage {
  width: 187px;
}
.siteMainHeaderInner  ul li{
  position: relative;
}
.siteMainHeaderInner a {
  font-size: 14px;
  color: #000;
}
.headerTopNav > ul > li > a {
  font-weight: 600;
}
.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  transition: .2s;
  transform: scale(.9);
  transform-origin: top center;
  opacity: 0;
  visibility: hidden;
  width: 240px;
  padding: 19px 0;
  margin: 2rem 0 0;
  list-style: none;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: -6px 6px 30px 0 rgba(0,0,0,.125);
}
li.menu-item-has-children:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sub-menu:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-100%);
  height: 2rem;
}
.sub-menu li.rz--separator:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: hsla(0,0%,58.8%,.3);
}
ul.sub-menu li a {
  display: block;
  padding: 4px 24px;
  color: #111;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}
.headerTopNav ul.sub-menu {
  right: 0;
}
.brk-nav-user picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.brk-nav-user picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
li.menu-item-has-children>a:after {
  content: "\F078";
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  font-size: 10px;
  opacity: .6;
  margin-right: 7px;
  transition: all ease 0.3s;
}
.mainMobilemenu li.active > a::after {
  transform: rotate(180deg);
}
.forlaptop {
  display: none !important;
}
.headerTopAction i {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background-color: #ddd;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerTopAction i svg {
  width: 17px;
  height: 17px;
}
.headerTopAction li > a, .headerTopNav > ul > li > a {
  padding: 0 6px;
  display: flex;
  align-items: center;
  position: relative;
}
.headerTopNav li.menu-item-has-children:first-child > a {
  padding-right: 0;
}
.headerTopAction  a.brk--pad i {
  font-style: inherit;
}
.notLoggedIn .headerTopAction nav.brk-nav.brk-nav-notifications {
  padding: 0 6px;
}
span.notification-badge {
  position: absolute;
  top: -7px;
  right: -5px;
  height: 20px;
  padding: 0 5px;
  margin: 0;
  min-width: 20px;
  background: linear-gradient(90deg,#f5a688,#f5a688);
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  border-radius: 50px;
  vertical-align: middle;
  font-family: 'Noto Sans Hebrew';
  font-weight: 700;
}
.listingData.gap-4 {
  gap: 0 !important;
}
nav.brk-nav.brk-nav-user li a span {
  margin-right: .5rem;
  color: #000;
  font-weight: 600;
}
.alertSidebarOverlay {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(17, 17, 17, 0.9);
  width: 100%;
  height: 100%;
  z-index: 180;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
.alertSidebarWrapper.active + .alertSidebarOverlay,
.alertSidebarWrapper.active{
  visibility: visible;
  opacity: 1;
}
.alertSidebarContentInner {
  border-top: 1px solid #ddd;
  padding-top: 1.5rem;
}
.alertSidebarWrapper {
  background-color: #fff;
  box-shadow: -3px 0 15px 0 rgba(0, 0, 0, .1);
  max-width: 500px;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  right: -100%;
  transition: all ease 0.3s;
  display: flex;
  flex-direction: column;
  z-index: 181;
}
.alertSidebarWrapper.active {
  right: 0;
}
.alertSidebarHeader {
  padding: 1.5rem;
}
.alertSidebarAction {
  padding: 0 1.5rem 1.5rem;
}
.alertSidebarAction .blackRadiusButton {
  width: 100%;
  background: #f1f1f1;
  color: #000;
}
.alertSidebarAction .blackRadiusButton:hover {
  background: #000;
  color: #fff;
}
.commonNotificationIcon {
  flex: 0 0 50px;
}
.closeCircle i {
  transition: all .3s;
  display: inline-block;
  height: 35px;
  min-width: 35px;
  padding: 0 4px;
  margin: 0 2px;
  background-color: #fff;
  color: #111;
  font-size: 16px;
  line-height: 35px;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  box-shadow: inset 0 0 0 1px #ddd;
  cursor: pointer;
}
.commonNotificationIcon i {
  transition: all .3s;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(90deg, #f5a688 , #f5a688);
  color: #fff;
  font-size: 21px;
}
.commonNotificationText {
  padding-right: 1rem;
  padding-left: 20px;
  flex: 1;
  position: relative;
}
.commonNotificationText::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2e89ff;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: none;
}
.commonSidebarNotification.newNotification .commonNotificationText::after{
  display: inline-block;
}
.commonSidebarNotification {
  padding: 0 .75rem;
}
.commonSidebarNotification a {
  color: #000;
  padding: 10px;
  text-decoration: none;
  border-radius: 10px;
}
.commonSidebarNotification a:hover {
  background-color: #f1f1f1;
}
span.notificationDate {
  font-size: 13px;
  color: #ccc;
  font-weight: 700;
} 
.commonNotificationText h6 {
  color: #555;
  font-size: 15px;
  margin: 0;
  line-height: 1.5;
}
.commonSidebarNotification:hover .commonNotificationText h6 {
  color: #000;
}
.blue-dot {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px; 
}
.highlight {
  background-color: yellow;
  color: black;
  padding: 0.2em;
}
/* Home Css */
.homeBanner {
  padding: 240px 0 220px;
}
.homeBannerImage {
  border-radius: 20px;
  height: 100%;
  width: calc(100% - 33%);
  top: 0;
  left: 0;
  margin-left: 0;
}
.homeBannerImage > img.position-absolute {
  object-fit: cover;
  border-radius: 30px;
}
.bannerCommonFieldLabel {
  cursor: pointer;
}
.homeBannerTitle {
  width: 100%;
  padding: 190px 20% 0;
  z-index: 1;
}
h1.vacaytionTitle {
  font-size: 40px;
  color: #f4a588;
}
.homeBannerTitle p {
  font-size: 28px;
}
.homeBannerForm {
  padding: 20px 0 0 20%;
}
.imageGallery.mobile_slider_mode {
  display: block;
}
ul.homeBannerButtons {
  gap: 15px;
}
.homeBannerButtons  button.orangeRadiusButton {
  padding: 8px 18px;
  font-size: 13px;
  color: #000;
  font-weight: 600;
  background-image: linear-gradient(82deg, #a1a5a8,#a1a5a8,#a1a5a8, #46474a);
  position: relative;
  color: #fff;
}
.homeBannerButtons li.active button.orangeRadiusButton {
  background: #f5a688;
  color: #000;  
}
.homeBannerCommonField > input,
.homeBannerCommonField > .p-component input{
  height: 50px;
  border: none;
  width: 100%;
  padding: 0 15px;
}
.homeBannerCommonField span.p-inputwrapper {
  display: block;
}
.homeBannerFormWrapInner {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -5px 5px 25px 0 rgba(0, 0, 0, 0.7);
}
.homeBannerCommonField {
  border-left: 1px solid #ddd;
  height: 61px;
  padding: 0 20px 0px;
  line-height: 61px;
  background-color: transparent;
  flex: 1 1 0;
  position: relative;
}
.homeBannerCommonField.bannerInputfld {
  padding: 0;
}
.homeBannerFormWrapInner  .homeBannerSubmit {
  flex: 0 0 61px;
  width: 61px;
  padding: 10px;
}
.homeBannerFormRightFields {
  flex: 0 0 calc(100% - 61px);
  width: calc(100% - 61px);
}
.bannerCommonFieldLabel span {
  display: block;
  position: relative;
  padding-left: 22px;
}
.bannerCommonFieldLabel span::before {
  display: block;
  position: absolute;
  content: "\F107";
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  top: 50%;
  margin-top: -10px;
  left: 0;
  pointer-events: none;
  transition: all ease 0.3s;
}
.homeBannerCommonField.openDropdown .bannerCommonFieldLabel span::before {
  transform: rotate(180deg);
}
.textWithButton a.orangeRadiusButton{
  color: #000;
}
button.homeBannerSubmitButton {
  height: 41px;
  width: 41px;
  border-radius: 50px;
  background: #f5a688;
  margin: 0;
  color: #000;  
}
button.homeBannerSubmitButton[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}
.homeBannerSubmitButton  .homeBannerSubmitLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 18px;
}
.buttonLoader{
  display: none;
}
.buttonLoader i.fa-sync{
  -webkit-animation: fa-spin 1.2s linear infinite;
  animation: fa-spin 1.2s linear infinite;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.showLoader span.homeBannerSubmitIcon {
  opacity: 0;
}
.showLoader .homeBannerSubmitLoader {
  display: block;
}
.bannerCommonFieldDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin-top: 1rem;
  background-color: #fff;
  border-top: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .1);
  min-width: 350px;
  max-width: 350px;
  display: none;
  z-index: 1;
}
.bannerCommonFieldDropdown label.commonCheckboxLabel span.checkBoxFieldText {
  font-size: 12px;
}
.openDropdown .bannerCommonFieldDropdown {
  display: block;
}
.bannerFieldDropdownContent {
  padding: 1.25rem;
  max-height: 50vh;
  overflow-y: auto;
  line-height: 24px;
}
.bannerFieldDropdownHeader{
  display: none;
}
.bannerFieldDropdownFooter {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  border-top: 1px solid #eee;
}
.bannerFieldDropdownFooter button.smallblackButton {
  margin: 0;
}
label.commonCheckboxLabel {
  padding-right: 35px;
  position: relative;
  cursor: pointer;
}
.checkboxListStyleBox {
  position: absolute;
  right: 0;
  border-radius: 5px;
  width: 23px;
  height: 23px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #111, 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}
.checkboxListStyleBox::before{
  opacity: 0;
  content: "";
  position: absolute;
  top: 7px;
  left: 6px;
  display: block;
  width: 10px;
  height: 6px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}
.commonCheckboxLabel input[type="checkbox"] {
  display: none;
}
label.commonCheckboxLabel input:checked+.checkboxListStyleBox {
  background-color: #000;
}
label.commonCheckboxLabel input:checked+.checkboxListStyleBox::before {
  opacity: 1;
}
.checkboxListStyle .commonCheckbox {
  margin: 12px 0;
}
.checkboxListStyle .commonCheckbox:first-child{
  margin-top: 0px;
}
.checkboxListStyle .commonCheckbox:last-child{
  margin-bottom: 0px;
}
.checkboxListStyle.column2 {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 12px;
}
.checkboxListStyle.column2 .commonCheckbox {
  margin: 0;
}
.specsBottom {
  max-width: calc(100% - 33%);
  margin-right: auto;
}
.p-autocomplete-panel.p-component {
  background: #fff;
  padding: 15px 10px 10px;
  margin-top: 0;
  border-radius: 10px;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
}
li.p-autocomplete-item {
  padding: 7px 10px;
  font-family: 'Open Sans';
  color: #111;
}
.p-autocomplete-panel.p-component li:hover{
  background-color: transparent;
}
.p-autocomplete-panel.p-component li p {
  margin: 0;
  font-size: 15px;
}
.p-autocomplete-panel.p-component li:hover p{
  color: #f5a688;
}

.homeRecommendations hr{
  height: 2px;
  background: #e7e7e7;  
}
.homeRecommendationsHead  h4 {
  font-size: 28px;
}
.homeRecommendationsHead h4 span span {
  display: none !important;
}
.homeRecommendations .homeRecommendationsHead p {
  margin-bottom: 2px;
  font-size: 19px;
}
.recommendTab .scrollingTabsWrapperInner .navIcon svg {
  width: auto;
  max-height: 30px;
}
.recommendTab .singleScrollingTab {
  font-size: 16px;
  padding-bottom: 0;
}
.recommendTab .singleScrollingTab > div {
  gap: 7px;
}
.meetVacaytionImg  a.btn-primary {
  bottom: 15%;
  right: 10%;
  min-width: 180px;
  text-align: center;
}
.meetVacaytionText  ul {
  max-width: 600px;
}
.meetVacaytionText ul::before {
  position: absolute;
  content: "";
  width: 3px;
  height: calc(100% - 15%);
  background: #000;
  top: 7%;
  right: 8px;
}
.meetVacaytionText > ul li {
  font-size: 16px;
  position: relative;
  padding-right: 50px;
}
.meetVacaytionText li:not(:last-child) {
  margin-bottom: 80px;
}
.meetVacaytionText > ul li::before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  background: #000;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.meetVacaytionText > ul li:nth-child(2)::before,
.meetVacaytionTextSlider ul li.p-carousel-indicator.p-highlight button,
.meetVacaytionTextSlider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f4a588;
}
.meetVacaytionTextSlider button.p-carousel-prev, .meetVacaytionTextSlider button.p-carousel-next {
  display: none;
}
.meetVacaytionSlide {
  direction: rtl;
  font-size: 18px;
  text-align: center;
  font-family: 'Open Sans';
}
.meetVacaytionSlide a {
  color: #000;
}
.meetVacaytionTextSlider ul.p-carousel-indicators,
.meetVacaytionTextSlider .swiper-pagination {
  position: relative;
  max-width: 200px;
  gap: 53px;
  margin: 30px auto 0;
}
.meetVacaytionTextSlider ul.p-carousel-indicators::before,
.meetVacaytionTextSlider .swiper-pagination::before {
  position: absolute;
  content: "";
  height: 3px;
  background: #000;
  top: 50%;
  right: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: -1;
}
.meetVacaytionTextSlider ul li button,.meetVacaytionTextSlider .swiper-pagination .swiper-pagination-bullet {
  height: 31px;
  width: 31px;
  background: #000;
  border-radius: 50%;
  right: 0;
  opacity: 1;
}
.meetVacaytionTextSlider li.p-carousel-indicator, .meetVacaytionTextSlider li.p-carousel-indicator button {
  margin: 0;
  transform: none;
}
.lvdDestinationImgOuter{
  max-width: 138px;
  margin: 0 auto;
}
.lovedDestinations .row {
  flex-wrap: nowrap;
  overflow: auto;
  padding-bottom: 10px;
}
.destinationColumn {
  padding-top: 24px;
}
.destinationColumnImg {
  position: relative;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  padding-bottom: 100%;
  transition: all ease 0.3s;
}
.destinationColumn:hover .destinationColumnImg {
  box-shadow: -10px 10px 25px 0 #0000004d;
  transform: translateY(-6px);
}
.destinationColumn  h5 {
  position: relative;
}
.orangebg{
  background: #f5a688;
}
.destinationColumn:hover h5 a {
  color: #f5a688;
}
.destinationColumnImg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
}
.homeHowWork .row {
  overflow: auto;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}
.homeHowWorkCol {
  border: 1px solid #e7e7e7;
  padding: 30px;
  border-radius: 10px;
}
.homeHowWorkCol  h4 {
  font-size: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f5a688;
  display: inline-block;
}
.homeHowWorkIcon svg,
.homeHowWorkIcon img {
  max-height: 50px;
  max-width: 50px;
}
.homeHowWorkCol p {
  font-size: 18px;
  color: #565656;
}
.travelersCardOuter {
  padding: 7px 15px 56px;
  direction: rtl;
  position: relative;
}
.travelersCardOuter .travelersCard{
  transition: all ease 0.3s;
}
.travelersSlider .swiper-slide:hover .travelersCard{
  margin-top: -7px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .085);
}
.travelersCardOuter:hover h5 a {
  color: #f5a688 !important;
}
.travelersCard  a.btn.btn-primary {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 12px;
  padding: 9px 25px 10px;
  min-width: auto;
}
.travelersCard {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.travelersCard hr {
  margin-top: auto;
}
.travelersUserInfo li svg,
.travelersUserInfo li img{
  width: 20px;
  margin-left: 5px;
}
.travelersUserInfo li:not(:last-child){
  margin: 0 0 10px;
}
.faqsInner .p-accordion-content {
  border: none;
  padding: 0px 0 30px;
  font-family: 'Open Sans';
}
.faqsInner .p-accordion-header a {
  border: none;
  background: transparent;
  display: flex;
  flex-flow: row-reverse;
  padding: 22px 0;
  font-weight: 400;
  color: #000;
  font-weight: 400;
  font-size: 18px;
}
.faqsInner .p-accordion .p-accordion-tab {
  margin-bottom: 0;
  border-bottom: 1px solid #e7e7e7;
}
.faqsInner .p-accordion-tab.p-accordion-tab-active a {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.faqsInner .p-accordion-header a svg {
  color: #f5a688;
  transform: rotate(90deg);
}
.faqsInner .p-accordion-tab-active .p-accordion-header a svg {
  transform: rotate(180deg);
}
ul.discStyle {
  padding-right: 15px;
  margin: 20px 0;
}
ul.discStyle li {
  list-style: disc;
}
.outerCommonBlogCard {
  padding: 7px 15px;
  direction: rtl;
  height: 100%;
}
.outerCommonBlogCard:hover > .commonBlogCard {
  transform: translateY(-7px);
  box-shadow: 0 0 30px rgba(0, 0, 0, .085);
}
.commonBlogCard {
  box-shadow: 0 0 0 1px hsla(0, 0%, 64.7%, .3);
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  transition: all ease 0.3s;
}
.commonBlogCardImg {
  padding-top: 66.6%;
  position: relative;
}
.commonBlogCardImg img{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.commonBlogCard h3.blogTitle {
  font-size: 22px;
  margin-bottom: 16px;
  padding: 1.5rem 1.5rem 0;;
}
.commonBlogCard h3.blogTitle a {
  color: #000;
}
.commonBlogCard:hover h3.blogTitle a,
.commonBlogCard:hover .blogUrllink a {
  color: #f5a688;
}
.commonBlogCardMeta {
  border-top: 1px solid hsla(0, 0%, 64.7%, .3);
  padding: 1.25rem;
}
.commonBlogCard  .blogExcerpt {
  padding: 0 1.5rem;
}
.blogDate {
  color: #565656;
  font-size: 13px;
  font-weight: 600;
}
.blogCategory a {
  display: inline-block;
  padding: 5px 12px;
  line-height: 20px;
  background-color: #f5a688;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 50px;
  vertical-align: top;
  text-decoration: none;
}
.blogExcerpt {
  font-size: 14px;
  line-height: 180%;
  color: #565656;
  text-align: justify;
  margin: 0 0 1.5rem;
}
.blogUrllink {
  padding: 1rem 1.5rem;
  margin: auto 0 0;
  border-top: 1px solid hsla(0, 0%, 64.7%, .3);
}
.blogUrllink a {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
ul.listingDataList li svg {
  width: 25px;
  height: 18px;
  margin-left: 9px;
}
ul.listingDataList {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid hsla(0, 0%, 74.5%, .4);
  pointer-events: none;
}
.commonListingRowOuter .p-galleria {
  border-radius: 10px;
  overflow: hidden;
  transition: all ease 0.3s;
}
.commonListingColumnOuter:hover .p-galleria {
  transform: translateY(-6px);
  box-shadow: -10px 10px 25px 0 rgba(0, 0, 0, .3);  
}
.commonListingColumnOuter:hover h6 a {
  color: #f5a688;
}
.commonListingPrice {
  color: #f5a688;
  font-size: 20px;
  margin-top: 12px;
}
.commonListingDesc {
  min-height: 128px;
}
.commonListingColumnOuter {
  padding: 6px 15px;
}
.repeaterAddress .modal-overlay {
  z-index: 999;
}
.modal-overlay .recommendations {
  list-style: none;
  padding: 20px 0 30px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 28px;
}
.commonListingContent > h6 {
  min-height: 3rem;
  margin-top: 15px;
}
.commonListingContent h6 a {
  color: #000;
}
.singleRecommendation > img {
  width: 22px;
  margin: 0 0 0 14px;
  height: 22px;
  object-fit: cover;
}
.singleRecommendation h5, .singleRecommendation p {
  margin: 0;
}
.profileInfoModal .modal-contents {
  background: #fff;
}
.profileInfoModal .modal-contents {
  max-width: 500px;
}
.recpListingInfoModal {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  background-color: #ffffff;
  padding: 24px;
  margin: 0 0 35px;
  display: flex;
  gap: 30px;
}
.topRightProfileImg {
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 10px;
  border-radius: 100%;
  overflow: hidden;
}
.topRightProfileImg img {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.recpListingInfoModal > div {
  min-width: 40%;
}
.recpListingInfoModal > div.topLeft .listingSocialLinks img {
  max-width: 24px;
}
.recpListingInfoModal > div.topRight {
  text-align: center;
  padding-right: 6px;
  padding: 0 7px 0 20px;
}
.recpListingInfoModal > div.topLeft h4 {
  font-size: 15px;
  margin: 0;
}
.singleRecommendation h5 {
  font-size: 15px;
  line-height: 1.5;
}
.listingBottom  img {
  max-height: 24px;
}
.totalRecommendations {
  background: #f5a688;
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commonListingRowOuter  .swiper-pagination,
.travelersSlider  .swiper-pagination,
.blogsSliderOuter  .swiper-pagination {
  display: none;
}
.commonHomeSlider {
  position: relative;
  margin: 0 -15px;
}
.commonHomeSlider .swiper-slide {
  height: auto;
}
.sliderNavButtons li:first-child {
  margin-left: 10px;
}
.leftNav  ul.sliderNavButtons {
  margin-right: 15px;
}
.leftNav ul li button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: transparent;
  color: #000;
  border: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftNav ul li button.disabled{
  opacity: 0.4;
}
.sliderTitleNav {
  gap: 20px;
  justify-content: space-between;
}
.sliderSectionHead .slidesCount {
  font-weight: 400;
}
.formGroup:not(:last-child) {
  margin-bottom: 1.5rem;
}
.formGroup label {
  display: block;
  font-size: .9rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin: 0 0 1rem;
}
.formGroup .p-dropdown.p-component.p-inputwrapper {
  width: 100%;
  border: none;
  background: #f1f1f1;
  height: 41px;
}
.formGroup .p-dropdown.p-component.p-inputwrapper span.p-dropdown-label {
  padding: 0;
  line-height: 41px;
  padding: 0 20px 0 10px;
}
.formGroup .p-dropdown-trigger svg {
  display: none;
}
.formGroup .p-dropdown-trigger{
  position: relative;
}
.formGroup .p-dropdown-trigger::before {
  display: inline-block;
  content: "\F107";
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  pointer-events: none;
  color: #000;
}
input.p-dropdown-filter {
  height: 33px;
  border-radius: 30px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;}
.bannerFieldDropdownContent span.p-dropdown-item-label > div img {
  display: none;
}
.p-dropdown-panel.p-component {
  background: #fff;
  padding: 20px;
  margin-top: -10px;
  border-radius: 10px;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
}
.p-dropdown-panel.p-component .p-dropdown-header {
  padding: 0 0 10px;
  background: transparent;
}
.p-dropdown-items-wrapper li.p-dropdown-item {
  padding: 7px 10px;
  font-size: 15px;
  line-height: 1.5;
}
.p-dropdown-items-wrapper li.p-dropdown-item img.flag{
  display: none;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #e4895c;
  background: #fff;
}
.p-inputtext:enabled:focus{
  box-shadow: none;
  border-color: #aaaaaa85;
}
.smallBlackdivide {
  height: 2px;
  width: 100%;
  max-width: 350px;
  background: #000;
  display: block;
  font-size: 0;
  margin: 0 auto;
}
.profileCountryRegion .smallBlackdivide {
  margin: 18px auto;
}
.commonTrevalKitRegion {
  text-align: start;
  border-top: 2px solid #ddd;
  padding: 20px 30px;
}
.trevalKitRegion {
  margin: 30px -55px 0;
  max-height: 400px;
  overflow: auto;
}
.commonTrevalKitRegion h5 {
  margin: 0 0 5px;
}
.countryRegion{
  cursor: pointer;
}

/* Single Listing */
.newSubmission button {
  padding: 10px 20px;
  margin-right: 6px;
  font-size: 14px;
  color: #000;
  line-height: 21px;
  font-weight: 600;
  border-radius: 10px;
  background: #f5a688;
  border: none;
  margin-top: 0;
}
.newSubmission button svg{
  margin-right: 10px;
}
.scrollingTabsWrapperInner .navIcon svg {
  width: auto;
  max-height: 24px;
}
span.price-free {
  color: #f5a688;
}
.singleScrollingTab.active {
  border-bottom: 2px solid #f4a988;
  color: #000;
}
.listingNav .scrollingTabsWrapper {
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 0 0 30px;
  padding: 18px 0 6px;
  background: #fff;
  border-bottom: 1px solid #dddddd;
  position: fixed;
  top: 60px;
  z-index: 9;
  width: 100%;
  right: 0;
  transition: all ease 0.3s;
}
.singleScrollingTab {
  font-size: 12px;
  color: #717171;
  cursor: pointer;
  margin-left: 0px;
  width: 80px;
  min-width: 80px;
  flex: 0 0 80px;
  flex-grow: 1;
  flex-basis: 0;
  padding-bottom: 13px;
  text-align: center;
}

.singleScrollingTab a {
  color: #717171;
}

.singleScrollingTab > a {
  gap: 5px;
}
.single-listing-tabs .single-listing-tab-item svg {
  height: 24px;
  width: 24px;
}
.scrollingTabsWrapperInner {
  flex-wrap: nowrap;
  overflow: auto;
  margin-bottom: -7px;
  width: 100%;
}
.scrollingTabsWrapperInner::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
.scrollingTabsWrapperInner::-webkit-scrollbar-track {
  background: transparent; 
}
.scrollingTabsWrapperInner::-webkit-scrollbar-thumb {
  background:transparent; 
}
.scrollingTabsWrapperInner::-webkit-scrollbar-thumb:hover {
  background: transparent; 
}
.imageGallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 28vh);
  margin: 0px;
  border-radius: 10px;
}
.gallery-item {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: inset 0 0 0 4px #fff;
  background-color: #f1f1f1;
}
.imageGallery .gallery-item:first-child {
  grid-column: 1/3;
  grid-row: 1/3;
}

.imageGallery[itemcount="5"] {
  grid-template-rows: repeat(2, 17vh);
  grid-template-columns: repeat(4, 1fr);
}
.imageGallery[itemcount="5"] .gallery-item:first-child {
  grid-column: 1/3;
  grid-row: 1/3;
}

.imageGallery[itemcount="1"] {
  grid-template-columns: 1fr;  
}
.imageGallery[itemcount="2"] {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 30vh);
}
.imageGallery[itemcount="2"] .gallery-item:first-child {
  grid-column: inherit;
  grid-row: inherit;
}
.imageGallery[itemcount="3"] {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 17vh);
}
.imageGallery[itemcount="3"] .gallery-item:first-child {
  grid-column: 1/2;
  grid-row: 1/3;
}
.imageGallery[itemcount="4"] {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 17vh);  
}
.imageGallery[itemcount="4"] .gallery-item:first-child {
  grid-column: 1/3;
  grid-row: 1/3;
}
.imageGallery[itemcount="4"] .gallery-item:last-of-type {
  grid-column: 3/5;
}
.imageGalleryOuter {
  margin: 0 -4px;
  border-radius: 10px;
  overflow: hidden;
}
.topBannerGallery .imageGallery[itemcount="1"] {
  grid-template-columns: 1fr;
  grid-template-rows: 34vh;
}
.topBannerGallery .imageGallery[itemcount="2"] {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 30vh);
}
.topBannerGallery .imageGallery[itemcount="3"] {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 28vh);
}
.topBannerGallery .imageGallery[itemcount="4"],
.topBannerGallery .imageGallery[itemcount="5"] {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 28vh);
}
.repeaterGalleryData span.planning_badges span {
  font-size: 14px;
}
.imageGallery .gallery-item img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.planning_badges {
  background: #fff;
  color: #000;
  padding: 2px 10px;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700 !important;
  text-decoration: none;
  margin: 18px 22px;
  position: absolute;
  top: 0;
  right: 0;
}
.topBannerGallery .imageGalleryOuter > p.text-center {
  font-size: 12px;
}
.imageGalleryOuter {
  border-radius: 12px;
  overflow: hidden;
}
.imageGalleryOuter::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  box-shadow: inset 0 0 0 4px #fff;
  border-radius: 12px;
  pointer-events: none;
  height: 100%;
  width: 100%;
}
.imageGallery .lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  direction: ltr;  
}
.lightboxTitle {
  text-align: center;
  padding: 12px 0;
}
.lightboxTitle p{
  font-size: 11px;
}
.lightboxTitle a {
  color: #f5a688;
  text-decoration: underline;
}
.imageGallery .lightbox span.prev::after,
.imageGallery .lightbox span.next::after,
.imageGallery .close::after {
  content: "\f060";
  font-family: "Font Awesome 5 Free";
  color: #000;
  font-weight: 900;
  font-size: 16px;
}
.imageGallery .lightbox span.next::after {
  content: "\f061";
}
.imageGallery .close::after{
  content: "\f00d";
}
.imageGallery .lightbox-image {
  max-width: 90%;
  max-height: 90%;
}

.imageGallery .close, .imageGallery .prev, .imageGallery .next {
  position: absolute;
  top: 50%;
  cursor: pointer;
  user-select: none;
  z-index: 1001;
  transition: all .3s;
  display: inline-block;
  height: 35px;
  min-width: 35px;
  padding: 0 4px;
  margin: 0 2px;
  background-color: #fff;
  color: #111;
  font-size: 0px;
  line-height: 35px;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  box-shadow: inset 0 0 0 1px #ddd;
}
.imageGallery .prev, .imageGallery .next{
  font-size: 0;
}
.imageGallery .close {
  top: 20px;
  right: 20px;
}

.imageGallery .prev {
  left: 20px;
  transform: translateY(-50%);
}

.imageGallery .next {
  right: 20px;
  transform: translateY(-50%);
}

.imageGallery .prev:hover, .imageGallery .next:hover {
  color: #ccc;
}

.repeaterAddress {
  margin-bottom: 20px;
}
.singleRepeater  .singleListingPanelTitleIcon {
  align-items: flex-start;
}
.singleListingPanelTitleIcon .listingMainTitleIcon {
  padding-right: 15px;
  margin-bottom: 0;
}
.singleRepeater.rz_shopping_3.mb-5 {
  margin-bottom: 10px !important;
}
.imageGallery[itemcount="1"] {
  grid-template-rows: 34vh;
}

.siteLoader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f5a688;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, .6);
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.loaderWrapper img{
  max-width: 180px;
  margin: 0a auto;
}
.listingData:has(.listingMapSection .fixcalendarbar) .listingMapSection{
  padding: 0 12px 0 12px;
}
.loggedIn .listingMapSection {
  flex: 500px;
  max-width: 500px;
  padding: 0 12px;
}
.stickySearchbar {
  position: sticky;
  top: 135px;
  z-index: 9;
  padding: 10px 0px 10px 0;
  background-color: #fff;
}
.stickySearchbarInner .icon {
  top: 10px;
  left: 15px;
  pointer-events: none;
}
.stickySearchbarInner input {
  width: 100%;
  border: 1px solid #ddd;
  line-height: 22px;
  height: 42px;
  padding: 10px;
  border-radius: 6px;
}
.listingMapSection.openmodal .leftSidebarMap {
  z-index: 9;
}
.listingMapSection.openmodal {
  position: relative;
  z-index: 999;
}
.listingMapSection.openmodal .fixcalendarbar {
  z-index: 1;
}
.stickySearchbarInner {
  position: relative;
}
.searchResults {
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  background: #fff;
  padding: 15px 10px 15px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
}
.searchResults li {
  padding: 5px 8px;
  cursor: pointer;
}
.searchResults li a {
  color: #111;
}
.searchResults li a:hover {
  color: #f5a688;
}
ul.topListingMeta {
  column-gap: 60px;
  margin-bottom: 36px;
}
.topListingMeta  li {
  display: flex;
  align-items: center;
  gap: 6px;
}
.topListingMeta li svg {
  width: 24px;
  height: 20px;
}
.listingData {
  margin: 0 -15px;
}
.listingInformation {
  flex: 0 0 calc(100% - 500px);
  max-width: calc(100% - 500px);
  padding: 0 15px 0 50px;
}
body.notLoggedIn .listingInformation {
  flex: 0 0 calc(100% - 315px);
  max-width: calc(100% - 315px);
}
.listingMapSection {
  flex: 0 0 500px;
  max-width: 500px;
  padding: 10px 15px 0;
}
body.notLoggedIn .listingMapSection {
  flex: 0 0 315px;
  max-width: 315px;
}
.listingData:has(.listingMapSection .fixcalendarbar) .listingInformation{
  padding: 0 12px 0 30px;
}
img.panelImage {
  width: 50px;
}
.panelIcon svg {
  max-width: 40px;
}
.subpanelIcon svg {
  max-width: 26px;
}
.highLightFeature .featureIcons svg {
  max-width: 30px;
  max-height: 23px;
}
.highLightFeature  p {
  font-size: 10px;
}
.exitIconBtn svg {
  max-width: 32px;
  max-height: 32px;
}
.satteliteIcon svg {
  max-width: 32px;
  max-height: 32px;
}
.dayActivityList svg {
  max-width: 32px;
  max-height: 50px;
}
ul.topListingInfo {
  column-gap: 30px;
  margin-bottom: 30px;
  row-gap: 20px;
}
.topListingInfo li svg {
  width: 24px;
  height: 20px;
  margin-left: 6px;
}
.topSectionSingleListing hr {
  margin: 20px 0;
}
.poi-info-window.gm-style {
  padding: 10px;
}
.gm-style .transit-container .gm-full-width {
  display: none;
}

.siteContent {
  margin-top: 167px;
}
.siteContent:has(div.homepageContent) {
  margin-top: 60px;
}
.listingMapSection.fixed {
  width: 30%;
  position: fixed;
  left: 0;
  top: 160px;
}
.listingMapSection .leftSidebarMap {
  position: sticky;
  top: 135px;
  margin: 0;
  padding: 10px 0 0;
}



/* Modal Css */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  height: 80%;
  max-width: 900px;
  max-height: 600px;
  position: relative;
}
.modal.lightModal {
  background: #fff;
}
.lightModal .modal-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 6px 32px rgba(0, 0, 0, .03);
  padding: 0;
}
.modal.ratingModal .modal-content {
  max-width: 828px;
}
.lightModal .modal_header {
  border-bottom: 1px solid #ddd;
  padding: 1.5rem;
  position: relative;
}
.modal.ratingModal .modal_header h4 {
  font-size: 19px;
  line-height: 35px;
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.ratingModal .modal_body {
  overflow: auto;
  padding: 1.75rem 1.5rem;
}
.ratingModal .modal_body h4 {
  font-size: 19px;
  line-height: 35px;
}
.lightModal .modal_header span.close {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 1.5rem;
  transform: translateY(-50%);
}
span.close i {
  transition: all .3s;
  display: inline-block;
  height: 35px;
  min-width: 35px;
  background-color: #fff;
  color: #111;
  font-size: 16px;
  line-height: 35px;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  box-shadow: inset 0 0 0 1px #ddd;
}
.rating-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  gap: 20px;
}
.rating-stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

.rating-label {
  margin-left: 10px;
}

.rating-container .rating-stars .react-stars span {
  transition: all .2s;
  display: inline-flex !important;
  width: 22px;
  padding: 0 3px;
  margin: 0 3px;
  border: 1px solid #ddd;
  line-height: 20px;
  color: #ddd;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
}
.ratingModal textarea.review-textarea {
  padding: 13px 15px;
  height: 120px;
  background: #f1f1f1;
  border: none;
  border-radius: 10px;
}
.ratingModal .submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.ratingModal input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
.ratingModal input[type="file"]::after {
  content: 'הוסף';
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 10px 30px 10px 20px;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  background-image: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.25));
  font-weight: 600;
  border-radius: 10px;
  min-width: 124px;
  text-align: center;
  justify-content: center;
  border: none;
  text-shadow: none;
  background-color: #000;
  position: absolute;
  top: 0;
  right: 0;
}
input[type="file"]::before {
  content: "\f062";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  font-weight: 900;
  position: absolute;
  color: #fff;
  z-index: 1;
  font-size: 14px;
  right: 32px;
  top: 11px;
}
.ratingModal input[type="file"]:hover::before {
  border-color: black;
}
.ratingModal input[type="file"]:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.photo-upload input[type="file"] {
  width: 124px;
  height: 41px;
  position: relative;
}
.submit-button {
  width: 10%;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}


.tabPanel {
  padding: 0 0 35px;
}
.prentDiv,
.parentDiv {
  padding: 0 0 20px;
}
.tabPanel.country_list {
  padding: 0 0 28px;
}
.singleListingPanelTitleIcon {
  align-items: center;
  padding-bottom: 20px;
}
.singleListingPanelTitleIcon h3 {
  padding-right: 15px;
  margin-bottom: 0;
}
.single-listing-tab-panel-title h5 {
  margin-bottom: 2px;
}
h2.singleListingPanelTitle {
  font-size: 36px;
  margin: 0;
}
.singleListingPanelSummary {
  width: 100%;
  margin-right: auto;
  margin-bottom: 20px;
}
.singleListingPanelSummary.topSectionSingleListing {
  margin-bottom: 40px;
}
.singleListingPanelSummary  p,
.reapterDescription p {
  font-size: 15px;
  font-family: 'Noto Sans Hebrew';
  margin: 0 0 10px;
}
.highLightFeature  p {
  font-size: 10px;
}
.singleListingPanelSummary p {
  text-align: justify;
}
.highLightFeature  p {
  text-align: center;
}
.singleListingPanelSummary p:last-of-type {
  margin: 0;
}
.attractionRepeaterData > .tabPanel > .aboutTabPanel > .singleListingPanelTitleIcon > .title_icon svg path,
.shoppingRepeaterData  > .tabPanel > .aboutTabPanel > .singleListingPanelTitleIcon > .title_icon svg path{
  fill: rgb(244, 169, 136);
}
.culinaryRepeaterData > .tabPanel > .aboutTabPanel > .singleListingPanelTitleIcon > .title_icon svg path {
  fill: rgb(0, 0, 0);
}
.singleListingPanelTitleIcon .title_icon svg {
  width: 100%;
  max-height: 40px;
  max-width: 40px;
} 
.singleListingPanelTitleIcon  .title_icon {
  width: 50px;
  flex: 0 0 50px;
  text-align: center;
  margin-right: -65px;
}
.listingInformation hr {
  width: calc(100% - 65px);
  margin: 0 auto 46px 0;
}
.singleListingPanelSummaryBg {
  background-size: cover;
  padding: 20px 15px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: calc(100% - 60px);
  margin-right: auto;
  margin-bottom: 34px;
}
.singleListingPanelSummaryBg:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.satteliteIconOuter {
  margin-bottom: 30px;
}
.listingTabActionButtons  .reservactionLink:not(:last-child) {
  padding: 0 0 15px 20px;
}
.listingTabActionButtons {
  margin: 40px 0 0;
  flex-wrap: wrap;
}
.listingTabActionButtons.d-flex:empty {
  display: none !important;
}
.attractionRepeaterData .tabPanel > hr {
  margin: 15px;
}
.mapContentSection a.viewOnmap {
  font-size: 11px;
}
.repeaterHighLightFeatures {
  justify-content: center;
  margin: 30px 0 25px;
  flex-wrap: wrap;
}
.repeaterHighLightFeatures .listingTabFeatureItem {
  padding: 11px 28px 7px;
  font-size: 10px;
  text-align: center;
  position: relative;
}
.repeaterHighLightFeatures .listingTabFeatureItem:not(:first-child)::before {
  content: '';
  width: 1px;
  height: 18px;
  top: 50%;
  right: 0;
  transform: translateY(-12px);
  position: absolute;
  background: #d9d9d9;
}
.repeaterGalleryData span.planning_badges {
  background: #001;
  display: flex;
  flex-flow: row-reverse;
  bottom: 0;
  top: auto;
}
.highLightFeature .featureIcons svg {
  max-width: 30px;
  max-height: 23px;
  width: initial;
}
.singleListingPanelSummary  h5 {
  margin-bottom: 2px;
}
.countriesData .title_icon svg {
  max-width: 28px;
}
.reapterTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reapterTitle svg {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
ul.singleListingSocialIcons {
  margin: 20px 0 28px;
}
ul.singleListingSocialIcons li:not(:last-child) {
  margin-left: 50px;
}
ul.singleListingSocialIcons li a svg {
  height: 40px;
  width: 40px;
}
.singleListingPanelSummary .container {
  max-width: 100%;
  padding: 0;
}
.mapIframe iframe {
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  height: 550px;
  margin-bottom: 20px;
}
.satteliteIcon a.btn, .satteliteIcon a.btn:hover {
  padding: 10px 18px;
}
.dayTitle h5 {
  font-size: 22px;
  margin: 0;
}
.dayActivity {
  position: relative;
  margin-bottom: 25px;
  font-size: 14px;
}
.dayActivityList {
  font-size: 14px;
}
.dayActivityListIcon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -6px;
  right: -53px;
}
.singleDayData:not(:last-child) {
  margin-bottom: 40px;
}
.title_icon:has(img[src=""]) {
  display: none;
}
.title_icon:has(img[src=""]) + h3 {
  padding-right: 0;
}
.scrollEyeIcon {
  cursor: pointer;
}
.dayActivity svg {
  width: 28px;
  height: 28px;
}
.faqQuestions .p-accordion-tab {
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.faqQuestions a.p-accordion-header-link {
  padding: 0 0 0 40px;
  color: #717171;
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 400;
  position: relative;
}
.faqQuestions a.p-accordion-header-link svg {
  position: absolute;
  left: 0;
  transform: rotate(90deg);
  margin: 0;
  top: 0;
  transition: all ease 0.3s;
  fill: #f4a988;
  color: #f4a988;
}
.faqQuestions .p-accordion-tab.p-accordion-tab-active a.p-accordion-header-link svg {
  transform: rotate(180deg);
  transition: all ease 0.3s;
}
.faqQuestions .p-accordion-tab .p-accordion-content {
  padding: 20px 0;
  border: none;
}
.faqQuestions .p-accordion-tab.p-accordion-tab-active a.p-accordion-header-link {
  color: #000;
  font-weight: 600;
}
.blurContent.showBlur{
  position: relative;
}
.blurContent.showBlur::after {
  position: absolute;
  content: "";
  height: 100%;
  width: calc(100% + 10px);
  top: 0;
  left: -5px;
  z-index: -1;
  transition: background-color .3s;
  background-color: hsla(0, 0%, 100%, .8);
  color: #000;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  z-index: 1;
}
.blurDescription {
  position: sticky;
  top: 58%;
  transform: translateY(-50%);
  max-width: 460px;
  margin: 160px auto auto;
  padding: 8px;
  text-align: center;
  z-index: 2;
  display: none;
}
.blurContent.showBlur .blurDescription{
  display: block;
}
.blurDescription a.orangeButton + p {
  font-size: 14px;
}
.blurDescription p {
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 0;
  font-family: 'Noto Sans Hebrew';
}
.commonListingRow .no-results {
  padding: 0 12px;
}
.leftSidebarList {
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 1.75rem;
  margin-top: 0px;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}
.commonSidebarList li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.commonSidebarList li img {
  max-width: 18px;
  flex: 0 0 18px;
  margin-left: 15px;
}
.leftSidebarList hr {
  width: 80px;
  margin-right: 33px;
}
.sideBarContact p.sidebarPrice {
  font-size: 26px;
  line-height: 1;
  text-align: center;
  color: #f5a688;
  margin-top: 10px;
}
.fixcalendarbar {
  position: fixed;
  left: 0;
  top: 140px;
  z-index: 10;
  background: #fff;
  max-width: 100%;
  height: calc(100vh - 140px);
  width: 50px;
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, .2);
  transition: transform .5s ease;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 9px;
  border-right: 1px solid #dddddd;
}
.fixcalendarbar .fixcalendarbar_top, .fixcalendarbar .fixcalendarbar_bottom {
  min-height: 16px;
}
.fixcalendarbar .calendar-btn span {
  text-align: center;
  display: block;
  font-weight: 700;
  color: #000;
  line-height: 1;
  font-size: 14px;
}
.fixcalendarbar_bottom_icon svg {
  width: 16px;
  height: 16px;
  color: #000;
}
.calendar-close-icon {
  background: #d9d9d9;
  display: block;
  transform: translate(-50%, -50%);
  top: 50%;
  right: -27px;
  border-radius: 0px 8px 8px 0px;
  height: 55px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
}
.calendar-close-icon:hover{
  background: #000;
}
.calendar-close-icon:before {
  position: absolute;
  top: -1px;
  left: 50%;
  content: "\F107";
  font-family: Font Awesome\ 5 Free;
  display: block;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  pointer-events: none;
  color: #fff;
  transform: translateX(-50%);
}
.calendar-close-icon:before {
  transform: rotate(-90deg);
  position: static;
  transition: all ease 0.3s;
}
.eventCalendarMain.calendarActive .calendar-close-icon.calendarOpen::before {
  transform: rotate(90deg);
}
.fixcalendarbar .calendar-img {
  margin-bottom: 6px;
}
.fixcalendarbar .calendar-img svg {
  width: 31px;
  height: 31px;
}
.eventCalendarMain {
  position: fixed;
  left: 0;
  top: calc(0% - (-140px));
  z-index: 9;
  background: white;
  padding: 10px 10px 10px 0;
  max-width: 100%;
  height: calc(100vh - 110px);
  width: 330px;
  transform: translateX(-110%);
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, .2);
  transition: transform .5s ease;
}
body:has( div.listingMapSection.openmodal) .eventCalendarMain {
  z-index: 1;
}
.eventCalendarMain.calendarActive {
  transform: translateX(0);
}
.eventCalendarMain .rbc-toolbar {
  flex-flow: row-reverse;
  padding-left: 10px;
  justify-content: flex-start;
}
body:has( div.listingMapSection.openmodal)  .calendarActionModal,
body:has( div.listingMapSection.openmodal) .eventCalendarMain {
    z-index: 1;
}
.topHeadSidebarCal .calendarDateDropdown {
  position: absolute;
  top: 46px;
  width: 100%;
  max-width: 110px;
}
.topHeadSidebarCal .calendarDateDropdown input.p-inputtext {
  height: 26px;
  font-size: 14px;
  border: none;
  padding: 0;
  color: #000;
  cursor: pointer;
}
span.p-calendar.p-component::after {
  position: absolute;
  top: 3px;
  left: 0;
  content: "\F107";
  font-family: Font Awesome\ 5 Free;
  display: block;
  margin-top: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  pointer-events: none;
}
.eventCalendarMain .rbc-toolbar span.rbc-toolbar-label {
  display: none;
}
.eventCalendarMain .rbc-toolbar span.rbc-btn-group {
  display: flex;
  flex-flow: row;
  gap: 10px;
}
.eventCalendarMain span.rbc-btn-group button,
.eventCalendarMain span.rbc-btn-group button:hover {
  width: 26px;
  height: 26px;
  font-size: 0;
  display: inline-block;
  padding: 0;
  border: 1px solid #2c3e50;
  background-color: #fff;
  border-radius: 50% !important;
  position: relative;
  transform: rotate(180deg);
}
.eventCalendarMain span.rbc-btn-group button::after {
  position: absolute;
  font-size: 14px;
  font-weight: 900;
  font-family: 'FontAwesome';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.eventCalendarMain span.rbc-btn-group button:nth-child(2):after {
  content: "\f104";
}
.eventCalendarMain span.rbc-btn-group button:last-child:after {
  content: "\f105";
}
.eventCalendarMain > div:not(.calendar-close-icon) {
  height: 100%;
}
.eventCalendarMain > div:not(.calendar-close-icon) .rbc-calendar {
  height: 100% !important;
}
.eventCalendarMain > div:not(.calendar-close-icon) .rbc-calendar .rbc-time-view .rbc-time-content {
  border: none;
  padding-left: 10px;
  padding-bottom: 90px;
}
.eventCalendarMain .rbc-time-content > * + * > * {
  border-left: none;
}
.eventCalendarMain .rbc-timeslot-group {
  border-bottom: 1px solid #d9d9d9;
  min-height: 52px;
  cursor: pointer;
}
.eventCalendarMain .rbc-time-slot {
  display: flex;
  align-items: flex-end;
  font-size: 12px;
}
.eventCalendarMain .rbc-time-slot span.rbc-label {
  position: relative;
  bottom: -25px;
  padding-left: 10px;
}
.eventCalendarMain .rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
  border: none;
}
.eventCalendarMain .rbc-day-slot.rbc-time-column > .rbc-timeslot-group:hover {
  background: #D9D9D9;
}
.rbc-time-header.rbc-overflowing{
  display: none;
}
.eventCalendarMain .rbc-event:focus, 
.eventCalendarMain .rbc-day-slot .rbc-background-event:focus{
  outline: none;
}
.eventCalendarMain .rbc-event, .eventCalendarMain .rbc-day-slot .rbc-background-event {
  background-color: #D9D9D9;
}
.eventCalendarMain .rbc-day-slot .rbc-event {
  padding: 5px 20px;
  font-size: 13px;
  line-height: 24px;
}
.eventCalendarMain .rbc-day-slot .rbc-event, .eventCalendarMain .rbc-day-slot .rbc-background-event{
  border: 1px solid #D9D9D9;
  background-color: #D9D9D9;
  color: #000;
  text-align: right;
}
.eventCalendarMain .rbc-day-slot .rbc-event-label {
  font-size: 13px;
  padding: 0 0 4px;
  line-height: 1.3;
}
.eventCalendarMain .rbc-day-slot .rbc-events-container {
  margin-right: 0px;
  pointer-events: none;
}
.eventCalendarMain .rbc-day-slot .rbc-events-container .rbc-event {
  pointer-events: all;
}
.dateInput span.p-calendar::after{
  display: none;
}
.eventCalendarMain .rbc-day-slot .rbc-event-content {
  order: -1;
}
.rbc-event {
  color: #000;
}
.eventCalendarMain .rbc-time-content > .rbc-day-slot .rbc-current-time-indicator,
.eventCalendarMain .rbc-toolbar span.rbc-btn-group button:first-child {
  display: none;
}
.eventCalendarMain .rbc-day-slot .rbc-time-slot {
  border-top: none;
}
.eventCalendarMain .rbc-today {
  background-color: #fff;
}
.eventCalendarMain .rbc-time-view {
  border-right: none;
}
.modal-overlay.calendarActionModal {
  background: #f0ecec;
  position: fixed;
  left: 0;
  top: 140px;
  z-index: 999999;
  padding: 20px;
  max-width: 100%;
  height: calc(100vh - 140px);
  width: 330px;
  transform: 0;
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, .2);
  transition: transform .5s ease;
  border-radius: 0;
  right: auto;
  display: block;
}
.modal-overlay.calendarActionModal .modal_body {
  padding: 0;
}
.modal-overlay.calendarActionModal .modal-contents span.close {
  height: 30px;
  width: 30px;
  position: static;
  margin-left: auto;
}
.modal-overlay.calendarActionModal .modal_header {
  display: flex;
  gap: 10px;
}
.modal-overlay.calendarActionModal .modal_header img, .modal-overlay.calendarActionModal .modal_header svg {
  max-height: 30px;
  width: 30px;
  cursor: pointer;
}
.modal-overlay.calendarActionModal .modal_header {
  margin-bottom: 30px;
}
.modal-overlay.calendarActionModal .modal_body .eventDetails .eventTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
.modal-overlay.calendarActionModal .eventDetails div {
  margin: 0 0 4px;
  font-size: 14px;
}
p.text-danger {
  padding: 0 15px;
  text-align: center;
}
.rbc-events-container .rbc-event {
  display: block;
}
.rbc-day-slot .rbc-event-content{
  line-height: 21px;;
}
.singleListingPanelTitleIcon .title_icon img {
  max-width: 100%;
  max-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.commonListingRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.commonListingRowOuter .commonListingCommonRow:not(:last-child) {
  margin-bottom: 50px;
}
.commonListingBox {
  flex: 0 1 33.3333%;
  display: flex;
  flex-direction: column;
  max-width: 33.3333%;
  padding: .75rem;
}
.commonListingBox .p-galleria.p-component {
  margin-bottom: 1rem;
}
.commonListingBox > h4 {
  font-size: 15px;
  font-weight: 700;
  margin: 0 0 .5rem;
}
.commonListingBottom {
  margin-top: 7px;
  padding-top: 7px;
  border-top: 1px solid hsla(0, 0%, 74.5%, .4);
  pointer-events: none;
  color: #aaa;
  font-size: 13px;
}
.commonListingBottom i {
  margin-left: 5px;
}
.p-galleria-item-container > button.p-galleria-item-nav {
  height: 100%;
  top: 0;
  transform: none;
  margin: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
.p-galleria-item-wrapper:hover .p-galleria-item-container > button.p-galleria-item-nav {
  opacity: 1;
  visibility: visible;
}
.p-galleria-item-container > button.p-galleria-item-prev {
  background: linear-gradient(270deg,transparent 0,rgba(0,0,0,.25));
}
.p-galleria-item-container > button.p-galleria-item-next {
  background: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25));
}
button.p-galleria-item-nav::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s;
  width: 28px;
  height: 28px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  font-size: 13px;
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.p-galleria-item-container > button.p-galleria-item-prev::after {
  content: "\f053";
}
.p-galleria-item-container > button.p-galleria-item-next::after {
  content: "\f054";
}
.p-galleria-item {
  width: 100%;
  padding-top: 66.4%;
}
.p-galleria-item img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.ReactModal__Content.lightModal {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 6px 32px rgba(0, 0, 0, .03);
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
}
div:not(.gallery-item,.smallBlackdivide):empty {
  display: none;
}
.ReactModal__Content.lightModal .modal_body {
  max-height: 85vh;
  min-height: 164px;
  overflow: auto;
  padding: 0.5rem 1.5rem 1.75rem;
  border-top: 1px solid #ddd;
}
.ReactModal__Content.lightModal .modal_image_text {
  padding: 1.25rem 1.5rem;
}
.ReactModal__Content.lightModal .modal_footer {
    padding: 1.5rem;
    border-top: 1px solid #ddd;
}
button.sendMessageButton {
  border: none;
  color: #f5a688;
  background: transparent;
  top: 0;
  right: 0;
  margin: 0;
  height: 46px;
  padding: 0 16px;
}
button.sendMessageButton i.fas {
  margin-left: 8px;
}
.modal_image_text  img {
  height: 46px;
  flex: 0 0 46px;
  width: 46px;
  border-radius: 50%;
  object-fit: cover;
}
.myMessageText {
  border-radius: 10px;
  border-top-right-radius: 0;
  background: linear-gradient(90deg, #f5a688, #f5a688);
  color: #fff;
  padding: .55rem .95rem;
}
.otherMessageText{
  padding: .55rem .95rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  border-top-left-radius: 0;
}
.commonMessageUserImg {
  flex: 0 0 40px;
  width: 40px;
  display: flex;
  justify-content: center;
}
.messageMeInner  .messageMeImg {
  margin-left: 1rem;
}
.commonMessageUserImg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.messageMeContent {
  position: relative;
}
span.messageTime {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  font-size: 12px;
  line-height: 30px;
  color: #777;
  white-space: nowrap;
}
.messageMeContent span.messageTime {
  left: auto;
  right: 0;
}
.messageDate {
  position: relative;
  display: inline-block;
  padding: 0 12px;
  background-color: #f1f1f1;
  color: #777;
  line-height: 28px;
  font-size: 11px;
  font-weight: 700;
  border-radius: 20px;
  z-index: 2;
}
.messageDateDiv {
  padding: 1.25rem 0 0;
  text-align: center;
}
.commonChatbubble {
  padding: 2rem 1.5rem 0;
}
.messageNotMeInner  .messageNotMeImg {
  margin-right: 1rem;
}
.ReactModal__Content.lightModal .modal_image_text h6 {
  font-size: 15px;
  padding-right: 24px;
}
.conversationTab {
  padding: 7px 1.75rem;
  margin: 0;
  background-color: #f5f5f5;
  font-size: 14px;
  text-align: center;
}
.chatMessageInput  textarea {
  transition: box-shadow .3s;
  width: 100%;
  height: 46px;
  line-height: 22px;
  padding: 12px 80px 12px 12px;
  border: 0;
  border-radius: 8px;
  background-color: #fff;
  vertical-align: top;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .15);
  resize: none;
}
.modal_body {
    max-height: 85vh;
    min-height: 164px;
    overflow: auto;
}
.ReactModal__Content.lightModal .modal_footer p {
  margin: 24px 0;
  font-size: 14px;
}
.ReactModal__Content.lightModal .modal_footer ul {
  font-size: 14px;
  padding-right: 15px;
}
.ReactModal__Content.lightModal .modal_footer ul li{
  list-style: disc;
}
.recommendationData {
  margin-top: 20px;
  width: calc(100% - 65px);
  margin-right: 65px;
}
.recommendationData ul {
  padding-right: 0px;
}
.recommendationData ul li {
  position: relative;
  margin: 0 0 10px;
}
.recommendationData ul li a {
  color: #000;
}
.recommendationData ul li::before {
  width: 13px;
  height: 13px;
  background: #f4a988;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  display: block;
  content: "";
  position: absolute;
  top: 5px;
  right: -42px;
}
.mobileCloseSide{
  display: none;
}
.ratingProgressBars {
  padding: 1.5rem;
  margin: 25px 0 2.5rem;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 40px;
  row-gap: 16px;
}
.ratingProgressBars .progress {
  height: 7px;
  flex: 0 0 29%;
  margin-right: auto;
}
.ratingProgressBars .rating-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ratingProgressBars .rating-item > span {
  order: -1;
}
.ratingProgressBars .rating-item  .rating-number {
  order: 2;
  font-size: 13px;
  padding-right: 15px;
  font-weight: 700;
}
.ratingProgressBars .progress .progress-bar {
  background: #f5a688;
  font-size: 0;
}
.reviewsContent .comment-header .comment-header-content {
  padding-right: 20px;
}
.reviewsContent .comment-header .comment-header-content .comment-author {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Noto Sans Hebrew';
}
.reviewsContent .comment-header .comment-header-content .comment-author .star_rating {
  color: #f5a688;
}
.reviewsContent .comment-header .comment-header-content .comment-date {
  font-size: 13px;
  color: #999;
}
.reviewsContent .comment {
  margin-bottom: 2rem;
}
.reviewsContent .comment .comment-header {
  margin-bottom: 15px;
}
.comment-gallery .imageGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 20px 0 0;
}
.comment-gallery .imageGallery > .gallery-item {
  height: 48px;
  width: 48px;
  background-size: cover;
  background-position: center center;
  border-radius: 5px;
  box-shadow: none;
  padding: 0;
}
.userAvtarIcon {
  height: 48px;
  flex: 0 0 48px;
  width: 48px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.confirmationModal h5 {
  margin: 15px 0 10px;
}
.confirmationModal .modal-actions button:nth-child(2) {
  margin-right: 15px;
}
.confirmationModal .modal-actions {
  margin-top: 0;
}
.confirmationModal .modal_body svg {
  width: 70px;
  height: 70px;
}
.instagramIframe iframe {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  height: 690px;
  min-width: 100%;
  border-radius: 10px;
  padding: 5px 5px 10px;
  margin-bottom: 20px;
}
.mytooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: rgb(108, 107, 107);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  left: 0;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  top: 125%;
  z-index: 9;
}
.mytooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.mytooltip i.fa-solid {
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.listingMainTitle .singleListingPanelTitleIcon .title_icon img.panelImage {
  border-radius: 0;
  width: auto;
} 
.eventDetailsIconText svg {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.eventDetails {
  padding: 10px 30px 0 20px;
}
.eventDetailsIconText {
  margin: 0 0 6px;
}
.eventDetailsIconText div {
  font-size: 14px;
}
.eventDetailsIconText a {
  color: #111;
  text-decoration: underline;
}
.eventDetailsButtons {
  gap: 15px;
  margin-top: 20px;
}
.eventDetailsButtons a.button {
  padding: 11px 30px 12px;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: white;
  border-radius: 10px;
  background: #f5a688;
  min-width: 125px;
  text-align: center;
}
.ratingTopcircle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
  background: linear-gradient(90deg, #f5a688, #f5a688);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-right: 20px;
}


/* Footer */
.footerNewsletter {
  border-bottom: 1px solid #eeeeee;
}
.footerNewsletter h2 {
  display: block;
  max-width: 565px;
  font-size: 37px;
  line-height: 130%;
  margin: 0 auto 2.5rem;
}
.footerNewsletter .container {
  max-width: 830px;
}
.footerNewsletterForm {
  position: relative;
}
body:has(footer.active) {
  overflow: hidden;
}
.footerNewsletterForm i.fas.fa-paper-plane {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 58px;
  height: 58px;
  line-height: 58px;
  text-align: center;
}
.footerNewsletter input[type="email"] {
  width: 100%;
  height: 58px;
  line-height: 58px;
  padding: 0 55px;
  border: 0;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  border-radius: 50px;
  color: #000;
}
input:focus,
textarea,
button{
  outline: none;
}
.footerPodcastListingwrap  h6 {
  font-size: 18px;
}
.footerNewsletterForm button {
  position: absolute;
  top: 0;
  bottom: 0;
  transition: all .3s;
  width: 58px;
  height: 58px;
  line-height: 58px;
  border: 0;
  background-color: transparent;
  text-align: center;
  left: 0;
  right: auto;
  transform: rotate(180deg);
  z-index: 1;
  color: #000;
}
span.form_error {
  display: block;
  color: #f5a688;
  font-size: 15px;
  margin-top: 12px;
  text-align: center;
}
.footerNewsletter  .marketing-consent {
  display: flex;
  justify-content: center;
}
.footerNewsletter .marketing-consent label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-right: 33px;
}
.footerNewsletter .marketing-consent label::before {
  content: "";
  position: absolute;
  top: 7px;
  right: 6px;
  display: block;
  width: 10px;
  height: 6px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  z-index: 1;
}
.footerNewsletter .marketing-consent label::after {
  content: "";
  display: inline-block;
  height: 23px;
  width: 23px;
  background: #fff;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #111,0 3px 8px 0 rgba(0,0,0,0.1);
  margin-left: 10px;
  position: absolute;
  right: 0;
}
.footerNewsletter .marketing-consent input{
  display: none;
}
.footerNewsletter .marketing-consent input:checked + label::after {
  background: #000;
}
.footerNewsletterForm div {
  width: 100% !important;
  text-align: center;
}
.footerNewsletterForm div iframe {
  margin: 20px auto;
  display: block;
}
p.footerNewsletterSummary.mt-4.text-center {
  display: block;
  max-width: 650px;
  margin: 1.5rem auto 0;
  font-size: 14px;
  color: #999;
  font-style: italic;
  font-weight: 400;
}
ul.footerSocial li a {
  display: block;
  width: 34px;
  height: 34px;
  padding: 0 !important;
  background-color: #000;
  color: #fff !important;
  line-height: 34px !important;
  text-align: center;
  border: 0 !important;
  border-radius: 50%;
}
.footerSocial li.instagram a {
  background-color: #405de6;
}
.footerSocial li.instagram a {
  background-color: #2d5f9a;
}
.footerPodcastListingwrap  .container {
  max-width: 1200px;
}
.footerPodcastListingInner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 15px;
}
.commonFtrPodcastBox {
  padding: 10px 0;
  display: flex;
}
.commonFtrPodcastBox:nth-child(3n) {
  justify-content: flex-end;  
}
.commonFtrPodcastBox:nth-child(3n+2) {
  justify-content: center;  
}
.commonFtrPodcastBox a {
  display: flex;
  align-items: center;
}
.commonFtrPodcastBoxImage {
  height: 60px;
  width: 60px;
  margin-left: 1.5rem;
}
.commonFtrPodcastBoxImage img {
  max-width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  transition: all ease 0.3s;
}
.commonFtrPodcastBox > a:hover img {
  transform: translateY(-6px);
  box-shadow: -10px 10px 25px 0 rgba(0, 0, 0, .3);
}
.commonFtrPodcastBox h6 {
  font-size: 17PX;
  font-weight: 700;
  color: #111;
  margin: 0;
  min-width: 70px;
}

.copyrigthContent p {
  font-size: 14px;
  margin: 0;
}
nav.brk-nav-footerBottomNavList ul li {
  position: relative;
  margin: 0 5px;
  padding: 0 8px;
}
nav.brk-nav-footerBottomNavList ul li:not(:last-child)::after {
  left: -8px;
  top: 54%;
  transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 4px;
  background: #000;
  position: absolute;
  border-radius: 50%;
}
.footerBottomNav a {
  font-size: 14px;
  color: #000;
}
.footerToggleContent {
  background: #f8f8f8;
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(150%);
  transition: transform 0.7s ease-in;
  z-index: 140;
}
footer.active .footerToggleContent {
  transform: translateY(-15.4%);
  border-bottom: 1px solid #abaeae;
}
.footerToggleContentOverlay {
  display: none;
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 130;
}
footer.active .footerToggleContentOverlay {
  display: block;
}
footer.active .siteMainFooter {
  position: relative;
  z-index: 999;
}
.footerToggleContent .footerToggleContentInner {
  display: flex;
  flex-wrap: wrap;
}
.footerToggleMenuColumn {
  flex: 0 25%;
  min-width: 25%;
  padding: 0 0.75rem;
  margin: 0;
}
.footerToggleMenuColumn:first-child {
  flex: 0 10%;
  min-width: 10%;
}
.footerToggleMenuColumn:nth-child(2) {
  flex: 0 15%;
  min-width: 15%;
}
.footerToggleMenuColumn li a {
  cursor: pointer;
  color: #000;
  padding: 8px 0;
  font-size: 15px;
  display: inline-block;
}
span.footerToggleContentClose {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000;
  cursor: pointer;
}
.footerToggleMenuBtn{
  cursor: pointer;
  padding: 0 8px;
  margin: 0 5px;
  position: relative;
  font-size: 14px;
}
.footerToggleMenuBtn:before {
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  content: "|";
  position: absolute;
  color: #000;
}
.footerToggleMenuBtn:after {
  content: "\F078";
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  font-size: 16px;
  left: -14px;
  position: absolute;
  top: 1px;
  opacity: .9;
  transition: transform 0.7s ease-in;
}
.footerToggleMenuBtn.active{
  color: #f5a688;
}
.footerToggleMenuBtn.active::after {
  transform: rotate(180deg);
  color: #f5a688;
}
span.footerToggleContentClose svg {
  height: 16px;
  width: 16px;
  color: #000;
  stroke: currentcolor;
  stroke-width: 3;
}
.footerToggleMenuColumn h6 {
  min-height: 27px;
  margin-bottom: 20px;
}

.mobpurchasebtn {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  padding: 5px 15px 0;
  bottom: 0;
}
.mobpurchasebtn > div {
  background: #000;
  border-radius: 10px;
  padding: 17px 15px;
}
.mobpurchasebtn > div .acess_rec_data.acess_rec_data_mobile, .mobpurchasebtn > .mobpurchasebtninner > a {
  width: 50%;
  background: transparent;
  color: #fff;
  padding: 0;
}
.mobpurchasebtn > div.mobpurchasebtninner > a {
  color: #f5a688;
  border-right: 2px solid #fff;
  border-radius: 0;
  padding: 0;
}
.mobpurchasebtn > div.mobpurchasebtninner > a img {
  max-width: 22px;
  margin-left: 10px;
}
.acess_rec_data.acess_rec_data_mobile {
  margin: 0;
  background: #f0f0f0;
  padding: 11px 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.acess_rec_data.acess_rec_data_mobile {
  margin: 0;
  padding: 11px 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.acess_rec_data.acess_rec_data_mobile a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
} 
.acess_rec_data.acess_rec_data_mobile.desktop a{
  color: #000;
}
.acess_rec_data.acess_rec_data_mobile a img {
  margin-left: 15px;
  max-width: 25px;
  position: relative;
  top: 2px;
}
.mobpurchasebtn a {
  width: 100%;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
}
a.for_buy_pro.orangeButton {
  padding: 13px 30px 12px;
}
.map-buttons {
  width: 100%;
  height: 60px;
  display: none;
  position: fixed;
  bottom: 64px;
  left: 0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  -webkit-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  transition: all ease 0.3s;
  z-index: 130;
}
.map-buttons .map-buttons-inner {
  flex: 0 0 auto;
  width: 50%;
  height: 60px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.map-buttons .map-buttons-inner .map-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  border-top-left-radius: 12px;
  border-right: 2px solid #fff;
  opacity: 1;
}
.single-rz_listing .map-button-fullscreen {
  border-top-right-radius: 0px;
  border-right: 0px solid #fff;
}
.brk-mobile-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  min-height: 64px;
  padding: 10px 0;
  background-color: hsla(0,0%,100%,.9);
  color: #000;
  box-shadow: 0 -1px 40px 0 rgba(0,0,0,.09);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 131;
}
.brk-mobile-row {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
}
.brk-mobile-bar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0 1rem;
  margin: 0 -4px;
}
.brk-mobile-bar li {
  flex: 1;
  margin: 0 4px;
  min-width: 0;
  text-align: center;
}
.brk-mobile-bar a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, .5);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  color: #000;
  margin: auto;
}
.brk-mobile-bar.brk--names-no span {
  display: none;
}
.whatsappfixedbtn {
  bottom: 20px;
  cursor: pointer;
  position: fixed;
  right: 20px;
  text-align: center;
  z-index: 99;
  background: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsappfixedbtn svg {
  width: 23px;
  height: 23px;
}
.whatsappfixedbtn a {
  color: #fff;
}

div.p-datepicker.p-component {
  width: 280px !important;
  min-width: 280px !important;
  padding: 0 0 1px;
}
.p-datepicker table td {
  padding: 4px 0;
}
.p-datepicker-calendar-container table.p-datepicker-calendar th {
  font-size: 14px;
}
.p-datepicker table td > span {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
  margin: 0;
  font-size: 14px;
  color: #000;
}
.p-datepicker table td > span:hover {
  background: #f5a688;
  border-radius: 4px !important;
  color: #000;
}
td.p-datepicker-today span {
  background: #f5a688;
  border-radius: 4px !important;
  color: #000;
}
.p-datepicker-calendar-container table.p-datepicker-calendar {
  margin: 0;
}
body:has(div.modal-overlay:not(.calendarActionModal)) .modal-overlay.calendarActionModal {
  z-index: 1;
}
.acess_rec_data.acess_rec_data_mobile.desktop {
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
  padding: 20px 0;
  margin: 30px 0 0;
}
.yNHHyP-marker-view svg {
  width: 35px;
  height: 35px;
}
.p-accordion-header-text {
  font-family: "Noto Sans Hebrew", sans-serif;
  line-height: 24px;
}
.leftSidebarMap > div {
  border-radius: 10px;
  overflow: hidden;
}
.listingMainTitleIcon {
  margin: 15px 0;
}
.topSectionSingleListing hr {
  margin: 0 auto 20px;
}
.lodgingRepeaterData hr {
  margin-bottom: 10px;
}
body.listing_type_paid-vacation-itinerary .itinerary_top_icons ul li {
  padding: 0 15px 8px;
}
.smallLoader{
  max-width: 40px;
  margin: 0 auto;
}


@media(min-width: 992px){
.container.topBannerGallery,
.container.singleListingWrap {
  padding: 0 80px;
}  
.notLoggedIn .container.topBannerGallery,
.notLoggedIn .container.singleListingWrap {
  max-width: 1330px;
}
.loggedIn .container.topBannerGallery,
.loggedIn .container.singleListingWrap {
  max-width: 1530px;
}
}

@media(min-width: 768px){
  .mobpurchasebtn{
    display: none !important;
  }
}
@media(max-width: 1199px){
  .listingMapSection .leftSidebarMap {
    top: 75px;
  }
  .siteMainHeaderInner {
    display: none !important;
  }
  .forlaptop {
    padding: 10px 0;
  }
  .siteMainHeader {
    box-shadow: none;
  }
  .scrollingTabsWrapper {
    top: 43px;
  }
  .fixcalendarbar {
    top: 123px;
    height: calc(100vh - 123px);
  }
  body.sticky .scrollingTabsWrapper {
    top: 0px;
  }
  body.sticky .fixcalendarbar {
    top: 80px;
    height: calc(100vh - 80px);
  }
  .scrollingTabsContainer a.headerLoginBtn{
    display: none;
  }
  body.sticky .siteMainHeader {
    display: none;
  }
  .siteContent {
    margin-top: 153px;
  }
  body.sticky .siteContent {
      margin-top: 110px;
  }
  .forlaptop {
    display: flex !important;
  }
  .modal-overlay.calendarActionModal{
    top: 123px;
    height: calc(100vh - 124px);
  }
  body.sticky .modal-overlay.calendarActionModal{
    top: 80px;
    height: calc(100vh - 80px);
  }
  body.sticky .stickySearchbar {
    top: 75px;
  }
  .eventCalendarMain {    
    top: 124px;
    height: calc(100vh - 124px);
  }
  body.sticky .eventCalendarMain {
    top: 81px;
    height: calc(100vh - 81px);
  }
  .recommendTab .singleScrollingTab {
    font-size: 12px;  
  }
  .siteMainHeaderInner.otherPages {
    display: flex !important;
  }
  .siteMainHeaderInner.otherPages .headerTopNav {
    display: none;
  }
  .siteMainHeaderInner.otherPages .headerTopAction,
  .siteMainHeader:has(.siteMainHeaderInner.otherPages) .forlaptop {
    display: none !important;
  }
  .loggedIn .listingMapSection {
    flex: 400px 1;
    max-width: 400px;
  }
  .listingInformation {
    flex-basis: calc(100% - 400px);
    max-width: calc(100% - 400px);
    padding-left: 25px;
  }
}

@media(max-width: 991px){
  h1, h2, h3 {
    font-size: 24px;
    line-height: 1.3;
  }
  .loggedIn .imageGalleryOuter {
    padding-left: 50px;
  }
  .listingData {
    margin: 0 0 40px;
    flex-wrap: wrap;
  }
  .headerTopNavMobile {
    position: fixed;
    height: calc(100% - 64px);
    width: 100%;
    display: block;
    top: 0;
    right: -100%;
    background: #fff;
    z-index: 130;
    transition: all ease 0.3s;
  }
  .headerTopNavMobile.active {
    right: 0;
  }
  .headerTopNavMobileHeader {
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
    text-align: center;
  }
  .headerTopNavMobileHeader img {
    max-width: 130px;
    margin: auto;
  }
  li.menu-item-has-children.active ul.mobile-sub-menu {
    display: block;
  }
  ul.mobile-sub-menu {
    background-color: #f5f5f5;
    display: none;
  }
  ul.mainMobilemenu li  a {
    padding: 10px 1rem;
    color: #000;
    line-height: 23px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
  }
  li.menu-item-has-children>a:after {
    font-size: 13px;
    margin-left: 0;
    margin-right: auto;
  }
  .scrollingTabsWrapper .container{
    padding-left: 0;
  }
  .listingInformation,
  .listingMapSection,  
  .loggedIn .listingInformation,
  .loggedIn .listingMapSection,
  body.notLoggedIn .listingInformation,
  body.notLoggedIn .listingMapSection {
    flex-basis: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }  
  .imageGallery,
  .imageGallery[itemcount="2"],
  .imageGallery[itemcount="3"],
  .imageGallery[itemcount="4"],
  .imageGallery[itemcount="5"],
  .imageGallery[itemcount="6"] {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
  }

  .imageGallery[itemcount="4"] .gallery-item:last-child {
    grid-column: inherit;
  }
  .gallery-item {
    padding-bottom: 100%;
  } 
  .imageGallery .gallery-item:first-child,
  .imageGallery[itemcount="2"] .gallery-item:first-child,
  .imageGallery[itemcount="3"] .gallery-item:first-child,
  .imageGallery[itemcount="4"] .gallery-item:first-child,
  .imageGallery[itemcount="5"] .gallery-item:first-child,
  .imageGallery[itemcount="6"] .gallery-item:first-child {
    grid-column: 1/5;
    grid-row: auto;
  }
  .topBannerGallery .imageGallery[itemcount="1"],
  .topBannerGallery .imageGallery[itemcount="2"],
  .topBannerGallery .imageGallery[itemcount="3"],
  .topBannerGallery .imageGallery[itemcount="4"],
  .topBannerGallery .imageGallery[itemcount="5"] {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
  } 
  .singleListingPanelSummary  .imageGallery {
    grid-template-rows: inherit;
  }
  .stickySearchbar {
    top: 124px;
  }
  body.sticky .stickySearchbar {
    top: 75px;
  }
  .singleListingPanelSummary {
    width: 100%;
    margin-right: auto;
  }
  .listingInformation hr {
    width: 100%;
  }
  /* .singleRepeater .singleListingPanelTitleIcon .repeaterGalleryData,
  .singleRepeater .singleListingPanelTitleIcon .repeaterHighLightFeatures,
  .singleRepeater .singleListingPanelTitleIcon .reapterDescription,
  .singleRepeater .singleListingPanelTitleIcon .listingTabActionButtons {
    margin-right: -50px; 
  } */
  .recommendationData {
    width: 100%;
    margin-right: 0;
  }
  .recommendationData ul {
    padding-right: 27px;
  }
  .recommendationData ul li::before {
    right: -27px;
  }
  .dayActivityListIcon {
    right: 0;
  }
  .fixcalendarbar  {
    top: 123px;
    height: calc(100vh - 187px);
  }
  .brk-mobile-bar{
    display: flex;
  }
  .fixcalendarbar {
    height: calc(100vh - 187px);
  }
  body.sticky .fixcalendarbar {
    top: 81px;
    height: calc(100vh - 145px);
  }
  .siteMainWrapper {
    padding-bottom: 64px;
  }
  .repeaterGalleryData span.planning_badges {
    bottom: auto;
    top: 0;
  }
  footer.active .footerToggleContent {  
    transform: translateY(-64px);
  }
  .homeBannerForm {
    padding: 20px 0 0 15%;
  }
  .homeBannerTitle {
    padding: 150px 15% 0;
  }
  .singleListingPanelTitleIcon  .title_icon {
    margin-right: 0;
  }
  .singleListingPanelSummaryBg {
    margin-right: 0;
    width: 100%;
  }
}
@media(max-width: 800px){
  .homeBanner {
    padding: 0;
  }
  .homeBannerOuter  .specsBottom {
    display: none !important;
  }
  .homeBannerImage {
    position: relative !important;
    width: 100%;
    padding: 80px 20px 120px;
  }
  .homeBannerTitle {
    top: 0;
    padding-top: 0;
    position: relative !important;
  }
  .homeBannerTitle h1.vacaytionTitle {
    font-size: 30px;
    margin-top: 2px;
  }
  .homeBannerTitle p {
    font-size: 18px;
  }
  body:has(.homeBannerCommonField.openDropdown) .homeBanner {
    padding: 0;
    z-index: 131;
  }
  .homeBannerForm {
    padding: 0;
  }
  .homeBannerForm {
    flex-direction: column-reverse;
    display: flex;
  }
  .homeBannerFormRightFields,
  .homeBannerFormWrapInner{
    flex-direction: column;
  }
  .homeBannerCommonField {
    border-left: none;
    border-bottom: 1px solid #ddd;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100% ;
  }  
  .homeBannerFormRightFields,
  .homeBannerFormWrapInner .homeBannerSubmit {
    flex-basis: initial;
    flex: 0 0 100%;
    width: 100%;
  }
  .homeBannerFormWrap {
    padding: 0px;
    margin-top: -90px;
  }
  .homeBannerForm ul.homeBannerButtons {
    margin-top: 30px;
  }
  .homeBannerForm {
    padding: 0 80px;
  }
  .homeBannerFormWrapInner .homeBannerSubmit button.homeBannerSubmitButton {
    width: 100%;
  }  
  .homeBannerFormWrapInner .homeBannerSubmit button.homeBannerSubmitButton {
    width: 100%;
    gap: 8px;
    flex-flow: row-reverse;
  }
  .homeBannerSubmit span.showOnMobile {
    display: block;
  }
  .homeBannerCommonField {
    line-height: 51px;
  }
  .bannerFieldDropdownHeader {
    display: block;
    border-bottom: 1px solid #eee;
    padding: 12px 1.25rem;
  }
  .bannerFieldDropdownHeader h5 {
    margin: 0;
    text-align: center;
    font-size: 15px;
  }
  .bannerCommonFieldDropdown .bannerFieldDropdownContent.styleScroll {
    max-height: initial;
    height: calc(100% - 116px);
  }
  .bannerFieldDropdownFooter button.smallblackButton {
    width: 100%;
  }
  .bannerCommonFieldDropdown {
    background-color: #fff;
    border-top: 1px solid #eee;
    height: calc(100% - 64px);
    min-width: 350px;
    max-width: 100%;
    display: none;
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 64px;
    left: 0;
    flex-direction: column;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto;
  }
  .homeRecommendationsHead  p.font-18 {
    font-size: 16px;
  }
  .homeBannerSubmitButton.showLoader span.showOnMobile {
    display: none;
  }
}
@media(max-width: 767px){
  .footerPodcastListingwrap .container {
    padding-left: 0;
  }
  .imageGalleryOuter {
    margin: 0px;
  }
  .repeaterAddress {
    padding-left: 40px;
  }
  .leftNav {
    display: none !important;
  }
  .travelers .slidesCount{
    display: none;
  }
  .orangeRadiusButton, .btn.btn-info, .orangeRadiusButton:hover, .btn.btn-info:hover,
  .btn-primary, .btn-primary:hover, .btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
    padding: 8px 40px 9px;
  }
  .homeRecommendations hr {
    height: 1px;
    background: #e7e7e7;
  } 
  .showOnMobile {
    display: block;
  }
  /* .ptb-90 {
    padding-top: 60px;
    padding-bottom: 60px;
  } */  
  .font-20 {
    font-size: 18px;
    line-height: 26px;
  }
  .homeBannerOuter.pt-90 {
    padding-top: 30px;
  }
  .blogsSliderOuter.ptb-90 {
    padding-bottom: 40px;
  }
  .homeRecommendations.ptb-90 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .homeRecommendationsHead  h4 {
    font-size: 20px;
  }
  .meetVacaytionTextSlider .swiper-pagination {
    position: relative;
    transform: none;
    display: flex;
    justify-content: space-between;
  }
  .meetVacaytionTextSlider ul li button {
    height: 31px;
    width: 31px;
    background: #000;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  h1, h2, h3 {
    font-size: 22px;
    line-height: 1.3;
  }
  .reviewsContent .comment-header .comment-header-content .comment-author span:last-child {
    font-size: 12px;
  }
  body.openedMobileMap {
    overflow: hidden;
  }
  body .mapContentSection {
    min-width: 249px;
    padding: 15px;
  }
  .eventCalendarMain {
    width: 100%;
  }
  .topListingInfo  li {
    font-size: 12px;
  }
  .lightModal .modal_header,
  .lightModal .modal_header span.close {
    padding: 1rem;
  }
  .modal-overlay .modal-contents {
    width: 100%;
    max-width: 90%;
  }
  .modal-overlay .modal-contents .modal_body {
    padding: 50px 30px 20px;
  }
  .profileCountryRegion .trevalKitRegion {
    margin: 30px -30px 0;
  }
  ul.topListingInfo {
    column-gap: 30px;
    row-gap: 15px;
  }
  .fixcalendarbar{
    display: none;
  }
  .imageGalleryOuter,
  .loggedIn .imageGalleryOuter {
    padding-left: 0;
  }
  .listingInformation,
  .listingData:has(.listingMapSection .fixcalendarbar) .listingInformation,
  .listingData:has(.listingMapSection .fixcalendarbar) .listingMapSection {
    padding: 0px;
  }
  .title_icon:empty {
    display: none;
  }
  .singleListingPanelTitleIcon .title_icon svg {
    width: 100%;
    max-height: 30px;
  }
  .stickySearchbar {
    padding: 0px 0px 10px 0;
  }
  body.sticky .stickySearchbar {
    padding: 10px 0px 10px 0;
  }
  .singleListingPanelTitleIcon h3,
  h2.singleListingPanelTitle{
    font-size: 22px;
  }
  .singleListingPanelSummaryBg {
    width: 100%;
    margin-right: auto;
  }  
  .footerPodcastListingInner {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
    gap: 15px;
  }
  .commonFtrPodcastBox a {
    flex-direction: column;
  }
  .commonFtrPodcastBox {
    flex: 0 0 80px;
    max-width: 80px;
    justify-content: center;
  }
  .commonFtrPodcastBoxImage {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .commonFtrPodcastBox h6 {
    font-size: 14px;
    min-width: auto;
    text-align: center;
  }
  .commonFtrPodcastBox:nth-child(3n) {
    justify-content: center;
  }
  .siteMainFooterInner,   
  .footerBottomNav {
    flex-direction: column;
  }
  nav.brk-nav-footerBottomNavList {
    margin: 10px 0;
  }
  .footerToggleMenuBtn:before{
    display: none;
  }
  .footerToggleMenuBtn {
    margin-right: 0;
    padding-right: 0;
  }
  .footerToggleContent .footerToggleContentInner {
    flex-direction: column;
    padding-top: 30px;
  }
  .footerToggleContent {
      height: calc(100% - 116px);
      overflow: auto;
  }
  footer.active .footerToggleContent {
    transform: translateY(-117px);
  }
  .footerToggleMenuColumn:not(:last-child) {
    margin-bottom: 30px;
  }
  .footerNewsletter h2 {
    font-size: 24px;
    text-align: center;
  }
  .footerNewsletter input[type="email"] {
    height: 48px;
    line-height: 48px;
    padding: 0 57px;
  }
  .footerNewsletterForm i.fas.fa-paper-plane,
  .footerNewsletterForm button {
    width: 48px;
    height: 48px;
    line-height: 48px;
  } 
  .dayListText {
    padding-top: 30px;
    padding-left: 0 !important;
  }
  .dayListText  b {
    position: absolute;
    top: 3px;
    right: 41px;
  }
  .dayActivityListIcon {
    right: 0;
    top: 0;
  }
  .dayActivity {
    align-items: flex-start !important;
  }
  .dayListText  p.mb-0:first-letter {
    font-size: 0;
  }
  .dayListText > div:nth-child(2) {
    margin-top: 8px;
  }
  .commonListingBox {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .rating-container {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 10px;
    gap: 10px;
  }
  .modal.ratingModal .modal-content {
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
  .ratingModal .modal_body {
    padding: 15px;
  }
  .singleListingPanelTitleIcon .title_icon img {
    max-height: 34px;
  }
  .modal-overlay.calendarActionModal {
    top: 124px;
    height: calc(100vh - 125px);
  }
  body.sticky .modal-overlay.calendarActionModal {
    top: 81px;
    height: calc(100vh - 82px);
  }
  .modal-overlay.calendarActionModal .modal_body {
    padding: 0;
  }
  .modal-overlay.calendarActionModal .modal-contents {
    max-width: 100%;
  }
  .mobileCloseSide svg {
    height: 25px;
    width: 25px;
  }  
  .topHeadSidebarCal > h5 {
    padding-right: 45px;
  }
  span.mobileCloseSide {
    position: absolute;
    top: 11px;
    right: 10px;
    display: inline-block;
  }
  .map-buttons {
    display: block;
  }
  .map-buttons .map-buttons-inner  div#fullscreen-btn {
    border: none;
  }
  .whatsappfixedbtn {
    bottom: 135px;
  }
  .listingMapSection .leftSidebarMap:has(.mobileMapOpened) {
    z-index: 999;
  }
  .leftSidebarMap > div.mobileMapOpened {
    position: fixed !important;
    height: calc(100% - 119px) !important;
    width: 100% !important;
    top: 0;
    left: 0;
    z-index: 99;
  }
  footer:has( ~ .map-buttons) .siteMainFooter {
    padding: 1.5rem 0 5rem  !important;
  } 
  .ratingTopcircle {
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      font-size: 12px;
  }  
  .meetVacaytions .row {
    flex-flow: column-reverse;
  }
  .meetVacaytions .row .meetVacaytionImg {
    margin-bottom: 30px;
  }
  .meetVacaytionText  h2 {
    text-align: center;
  }
  .commonHomeSlider button.p-carousel-prev, .commonHomeSlider button.p-carousel-next {
    top: -80px;
  }
  .homeHowWork .col-3 {
    min-width: 300px;
    padding: 0;
  }
  .lovedDestinations .row .col-2 {
    min-width: 110px;
  }
  .destinationColumnImg img {
    max-height: 45px;
    max-width: 45px;
  }
  .destinationColumn  h5 {
    font-size: 16px;
  }
  .destinationColumnImg {
    height: 88px;
    width: 88px;
    padding-bottom: 0;
  }
  .travelersSlider.commonHomeSlider {
    gap: 12px;
    margin: 0;
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
  }
  .travelersCard img.traveler-avatar {
    height: 65px;
    width: 65px;
  }
  .travelersCardOuter {
    padding-left: 0;
    padding-right: 0;
  }
  .travelersInner h6.slidesCount {
    display: none;
  }
  .travelersSlider.commonHomeSlider .travelersCardMob {
    width: 190px;
    flex: 0 0 190px;
  }
  .meetVacaytionImg a.btn-primary {
    min-width: auto;
    right: 0;
    padding: 7px 32px 8px;
  }
  .textWithButton.ptb-90 {
    padding: 50px 0;
  }  
  .commonListingRowOuter ul.p-carousel-indicators {
    display: flex;
  }
  .commonListingRowOuter ul.p-carousel-indicators li.p-carousel-indicator {
    margin: 0 6px;
  }
  .sliderTitleNav h2 {
    flex: 0 0 100%;
  }
  .mb-5.sliderTitleNav {
    margin-bottom: 40px !important;
  }
  .commonListingRowOuter ul.p-carousel-indicators li button {
    height: 14px;
    width: 14px;
    padding: 0;
    border-radius: 50%;
    background: #f0f0f0;
    margin: 0;
  }  
  .commonListingRowOuter ul.p-carousel-indicators li.p-highlight button {
    background: #000;
  }
  .travelers  .container {
    padding-left: 0;
  }
  .leftNav ul li button {
    height: 34px;
    width: 34px;
  }
  .leftNav  ul.sliderNavButtons {
    margin-right: 8px;
  }
  .sliderNavButtons li:first-child {
    margin-left: 6px;
  }
  .homeHowWorkCol p {
    font-size: 16px;
  }
  h4 {
    font-size: 20px;
  }
  .commonBlogCard h3.blogTitle {
    font-size: 18px;
  }
  .meetVacaytionSlide {
    font-size: 16px;
    margin: 10px 0 20px;
  }
  .meetVacaytionTextSlider ul li button,
  .meetVacaytionTextSlider .swiper-pagination .swiper-pagination-bullet {
    height: 20px;
    width: 20px;
    margin: 0 !important;
  }
  .meetVacaytionTextSlider ul.p-carousel-indicators::before{
    height: 2px;
  }
  .homeRecommendations  hr {
    margin-left: -15px;
    margin-right: -15px;
  }
  .travelersCard a.btn.btn-primary {
    padding: 8px 20px 9px;
    min-width: 120px;
  }
  .faqsInner .p-accordion-header a {
    font-size: 16px;
  }
  .homeHowWork .container {
    padding-left: 0;
  }
  .commonHomeSlider {
    margin: 0 -10px;
  }
  .commonListingColumnOuter {
    padding: 6px 10px;
  }
  .outerCommonBlogCard{
    padding-left: 10px;
    padding-right: 10px;
  }
  .commonListingOuterWrap .commonListingRowOuter.commonHomeSlider,
  .blogsSliderOuter .blogsSliderWrap.commonHomeSlider {
    margin-left: -15px;
  }
  ul.singleListingSocialIcons li a svg {
    height: 30px;
    width: 30px;
  }
  .meetVacaytionTextSlider .swiper-pagination::before{
    height: 2px;
  }
  .closeMobileMapIcon {
    right: 16px;
  }
}
@media(max-width: 640px){
  .singleRepeater {
    position: relative;
  }
  .singleRepeater > .singleListingPanelTitleIcon .title_icon {
    position: absolute;
    top: 7px;
    right: 0;
  }
  .singleRepeater > .singleListingPanelTitleIcon .imageGalleryOuter {
    margin: 0px;
  } 
  .singleRepeater .singleListingPanelTitleIcon .singleListingPanelSummary .reapterTitle,
  .singleRepeater .singleListingPanelTitleIcon .singleListingPanelSummary .repeaterAddress {
    padding-right: 65px;
  }
  .singleListingPanelSummary > .repeaterAddress .reapterTitle {
    padding-right: 0 !important;
  }
  .featureIcons {
    width: 50px;
  }
  .ReactModal__Content.lightModal .modal_body {
      max-height: 31vh;
  }
  .repeaterHighLightFeatures {
    flex-direction: column;
    align-items: flex-start;
  }
  .highLightFeature {
    flex-direction: inherit !important;
    align-items: center;
  }
  .highLightFeature p,
  .repeaterHighLightFeatures .highLightFeature p{
    font-size: 14px;
    margin: 0;
  }
  .repeaterHighLightFeatures .listingTabFeatureItem {
    padding: 11px 0px 7px;
    width: 100%;
  }
  .highLightFeature .featureIcons svg {
    max-width: 30px;
    max-height: 30px;
    width: 30px;
  }
  .highLightFeature.gap-1 {
      gap: 15px !important;
  }
  .repeaterHighLightFeatures .listingTabFeatureItem:not(:first-child)::before{
    display: none;
  }
  .imageGallery.mobileSlider {
    display: block;
  }
  .imageGalleryOuter::after {
    display: none;
  }
  .imageGallery.mobileSlider div.p-galleria {
    max-width: 100% !important;
  }
  .imageGallery.mobileSlider .p-carousel {
    direction: ltr;
  }
  .imageGallery.mobileSlider .p-carousel button.p-carousel-next, .imageGallery.mobileSlider .p-carousel button.p-carousel-prev {
    display: none;
  }
  .imageGallery.mobileSlider .p-carousel-item {
    width: 100%;
    padding-top: 324px;
    position: relative;
  }
  .imageGallery.mobileSlider .p-carousel-item img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100% !important;
    object-fit: cover;
    object-position: top center;
    height: 100%;
  }
  .imageGallery.mobileSlider .p-carousel ul.p-carousel-indicators {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 10px;
  }
  .imageGallery.mobileSlider .p-carousel ul.p-carousel-indicators li{
    margin-left: 5px;
    margin-right: 5px;
  }
  .imageGallery.mobileSlider .p-carousel ul.p-carousel-indicators li button {
    width: 8px;
    height: 8px;
    cursor: pointer;
    color: transparent;
    padding: 0 2px;
    border: none;
    background-color: #fff;
    border-radius: 50%;
    text-decoration: none;
    text-align: center;
    opacity: 0.6;
  }
  .imageGallery.mobileSlider .p-carousel ul.p-carousel-indicators li.p-carousel-indicator.p-highlight button.p-link {
    opacity: 1;
  } 
  .imageGallery.mobileSlider .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: transparent;
    padding-bottom: 10px;
  }
  .imageGallery.mobileSlider .p-galleria-item img {
    max-height: 100% !important;
  }
  .commonHomeSlider .swiper-pagination {
    position: static;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .swiper-pagination span.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    cursor: pointer;
    color: transparent;
    padding: 0 2px;
    border: none;
    background-color: #f0f0f0;
    border-radius: 50%;
    text-decoration: none;
    text-align: center;
    opacity: 0.6;
  }
  .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #000;
  }
  .imageGallery.mobileSlider .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: transparent;
    padding-bottom: 10px;
  }
  .homeHowWork .row {
    gap: 12px;
    margin: 0;
    padding-left: 15px;
  }
  .homeHowWork .col-4 {
    min-width: 290px;
    padding: 0;
  }
  .homeHowWorkCol  h4 {
    font-size: 18px;
  }
  .recommendTab {
    margin-left: -15px;
  }
}
@media(max-width: 575px){
  .homeBannerImage {
    padding: 80px 20px 90px;
  }
  .ratingProgressBars {
    grid-template-columns: repeat(1,1fr);
  }
  .homeBannerForm {
    padding: 0 20px;
  }
  .homeBannerFormWrap {
    margin-top: -60px;
  }
  .meetVacaytionTextSlider {
    max-width: 320px;
    margin: auto;
  }
  .homeHowWorkCol {
    padding: 24px;
  }
  .homeBannerCommonField  span.checkBoxFieldText {
    font-size: 12px;
  }
}